// <!-- PLUGINS -->
import { useAxios as axios } from '@/plugins/axios';
import { AxiosError } from 'axios';

// <!-- UTILITIES -->
import { Result } from 'true-myth/dist/result';
import { ErrorResult } from '@/utils/server';
import { User } from '@/models/v2/users';
import { Account } from '@/models/v2/accounts';
import { OrganizationModel as Organization } from '@/models/v2/organizations';

// <!-- ROUTES -->
/** @type {Readonly<globalThis.Cart.Routes>} */
const URI = {
    signup: () => `signup/register`,
    upgrade: () => `subscriptions/upgrade/`,
    deliver: () => `subscriptions/deliver/`,
};

// <!-- ENDPOINTS -->

/**
 * Submit form data from the signup flow.
 * @type {globalThis.Cart.API.RegistrationResource}
 */
export const submitRegistration = async (request, config) => {
    try {
        /** @type {globalThis.Cart.Response.RegisteredResource} */
        const response = await axios().post(URI.signup(), request, config);

        // In the case of an error, return the properly typed error result.
        if (response.status !== 200) {
            return /** @type {import('true-myth').Result<{ user: globalThis.User.Model; account: globalThis.Account.Model; organization: globalThis.Organization.Model; redirect: string | null; }, AxiosError>} */ (
                /** @type {unknown} */ (
                    Result.err(
                        new Error('Failed to redirect to the shopping cart.')
                    )
                )
            );
        }

        // Convert data into models.
        const data = response.data;
        const user = User.create.fromPayload(data.user);
        const account = Account.create.fromPayload(data.account);
        const organization = Organization.fromPayload(data.organization);
        const redirect = data.redirect ?? 'login';

        // Prepare the appropriate result.
        const result = Result.ok({
            user,
            account,
            organization,
            redirect,
        });

        // Return the result.
        return result;
    } catch (e) {
        return Result.err(/** @type {AxiosError} */ (e));
    }
};

/**
 * Update a single organization subscription id.
 * @type {globalThis.Cart.API.UpgradeResource}
 */
export const upgradeSubscription = async (request, config) => {
    try {
        /** @type {globalThis.Cart.Response.UpgradedResource} */
        const response = await axios().post(URI.upgrade(), request, config);
        const result =
            response.status === 200
                ? Result.ok(response.data)
                : Result.err(new Error('Failed to redirect to shopping cart.'));
        return /** @type {import('true-myth').Result<{ location: string }, Client.Error>} */ (
            result
        );
    } catch (e) {
        return e instanceof AxiosError
            ? ErrorResult.fromAxiosError(e, request)
            : ErrorResult.from(e);
    }
};

/**
 * Deliver pending subscription as paid to the specified organization.
 * @type {globalThis.Cart.API.DeliverResource}
 */
export const deliverSubscription = async (request, config) => {
    try {
        /** @type {globalThis.Cart.Response.DeliveredResource} */
        const response = await axios().post(URI.deliver(), request, config);
        // In the case of an error, return the properly typed error result.
        if (response.status !== 200) {
            return /** @type {import('true-myth').Result<never, AxiosError>} */ (
                /** @type {unknown} */ (
                    Result.err(new Error('An unknown error occurred.'))
                )
            );
        }
        // Prepare the appropriate result.
        const result = Result.ok(null);
        // Return the result.
        return result;
    } catch (e) {
        return Result.err(/** @type {AxiosError} */ (e));
    }
};

/** @type {Readonly<globalThis.Cart.API>} */
export default {
    submitRegistration,
    upgradeSubscription,
    deliverSubscription,
};
