// <!-- FORWARD EXPORTS -->
export { PlanPayload } from '@/payloads/v2/plans/PlanPayload';
export { PlanModel } from './PlanModel';

// <!-- IMPORT -->
import { PlanPayload } from '@/payloads/v2/plans/PlanPayload';
import { PlanModel } from './PlanModel';

// <!-- NAMESPACE EXPORTS -->
export const Plan = {
    Payload: PlanPayload,
    Model: PlanModel,
};
