// <!-- PLUGINS -->
import { useAxios as axios } from '@/plugins/axios';
import { AxiosError } from 'axios';

// <!-- UTILITIES -->
import { Result } from 'true-myth/dist/result';
import { ErrorResult } from '@/utils/server';

// <!-- MODELS -->
import { Plan } from '@/models/v2/plans';
import { Organization } from '@/models/v2/organizations';
import { Subscription } from '@/models/v2/subscriptions';

// <!-- ROUTES -->

/** @type {Readonly<globalThis.Plan.Routes>} */
const URI = {
    index: () => `plans`,
    show: (id) => `plans/${id}`,
    organizations: (id) => `plans/${id}/organizations`,
    subscriptions: (id) => `plans/${id}/subscriptions`,
};

// <!-- ENDPOINTS -->

/**
 * Get all plans available in the database.
 * @type {globalThis.Plan.API.FetchIndex}
 */
export const fetchPlans = async (config) => {
    try {
        /** @type {globalThis.Plan.Response.FetchedIndex} */
        const response = await axios().get(URI.index(), config);
        const payloads = response.data.data.map(Plan.Payload.create);
        const models = payloads.map(Plan.Model.fromPayload);
        const result = Result.ok(models);
        return result;
    } catch (e) {
        return e instanceof AxiosError
            ? ErrorResult.fromAxiosError(e)
            : ErrorResult.from(e);
    }
};

/**
 * Get an plan by its identifier.
 * @type {globalThis.Plan.API.FetchResource}
 */
export const fetchPlanById = async (request, config) => {
    try {
        /** @type {globalThis.Plan.Response.FetchedResource} */
        const response = await axios().get(URI.show(request.id), config);
        const payload = new Plan.Payload(response.data.subscription_level);
        const model = payload.toModel();
        const result = Result.ok(model);
        return result;
    } catch (e) {
        return e instanceof AxiosError
            ? ErrorResult.fromAxiosError(e, request)
            : ErrorResult.from(e);
    }
};

/**
 * Get plan organizations.
 * @type {globalThis.Plan.API.FetchOrganizations}
 */
export const fetchPlanOrganizations = async (request, config) => {
    try {
        /** @type {globalThis.Plan.Response.FetchedOrganizations} */
        const response = await axios().get(
            URI.organizations(request.id),
            config
        );
        const payloads = response.data.organizations.map(
            Organization.Payload.create
        );
        const models = payloads.map(Organization.Model.fromPayload);
        const result = Result.ok(models);
        return result;
    } catch (e) {
        return e instanceof AxiosError
            ? ErrorResult.fromAxiosError(e, request)
            : ErrorResult.from(e);
    }
};

/**
 * Get an plan subscriptions.
 * @type {globalThis.Plan.API.FetchSubscriptions}
 */
export const fetchPlanSubscriptions = async (request, config) => {
    try {
        /** @type {globalThis.Plan.Response.FetchedSubscriptions} */
        const response = await axios().get(
            URI.subscriptions(request.id),
            config
        );
        const payloads = response.data.subscriptions.map(
            Subscription.Payload.create
        );
        const models = payloads.map(Subscription.Model.fromPayload);
        const result = Result.ok(models);
        return result;
    } catch (e) {
        return e instanceof AxiosError
            ? ErrorResult.fromAxiosError(e, request)
            : ErrorResult.from(e);
    }
};

/** @type {Readonly<globalThis.Plan.API>} */
export default {
    fetchPlans,
    fetchPlanById,
    fetchPlanOrganizations,
    fetchPlanSubscriptions,
};
