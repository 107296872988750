// <!-- ENUMS -->
import { CountryName, StateName, ProvinceName } from '@/enums';

// <!-- NAMED EXPORTS -->
export const states = StateName.values;
export const provinces = ProvinceName.values;

/**
 * Use region subdivisions from the appropriate country.
 * @param {Extract<Country.Name, 'United States' | 'Canada'>} country Country to request states/provinces for.
 */
export const useCountrySubdivisions = (country = CountryName.ByISO3['USA']) => {
    switch (country) {
        case CountryName.ByISO3['CAN']:
            return provinces;
        case CountryName.ByISO3['USA']:
        default:
            return states;
    }
};

/**
 * Default exports.
 */
export default {
    states,
    provinces,
};
