// <!-- UTILITIES -->
import { countries, useCountries } from '@/hooks/useCountries';
import { states, provinces } from '@/hooks/useStates';

// <!-- SUBMODULES -->
/** Country selector module. */
class CountrySelector {
    /** Get the country option values. */
    static get countries() {
        return useCountries();
    }

    /** @returns {Options.OptionRecord<'Select Country', ''>} */
    static get placeholder() {
        return {
            label: 'Select Country',
            value: '',
            attrs: { disabled: true },
        };
    }

    /**
     * Instantiate a country selector.
     */
    constructor() {
        /** The placeholder */
        this.placeholder = CountrySelector.placeholder;

        /** The source map of countries. */
        this.countries = CountrySelector.countries;
    }

    /** @returns {Options.OptionRecord<'Select Country' | countries[number], '' | countries[number]>[]} */
    get options() {
        return [
            this.placeholder,
            ...this.countries.map((c) => ({
                label: c,
                value: c,
            })),
        ];
    }
}

/** State selector module. */
class StateSelector {
    /** Get the country option values. */
    static get states() {
        return states;
    }

    /** @returns {Options.OptionRecord<'Select State', ''>} */
    static get placeholder() {
        return {
            label: 'Select State',
            value: '',
            attrs: { disabled: true },
        };
    }

    /**
     * Instantiate a country selector.
     */
    constructor() {
        /** The placeholder */
        this.placeholder = StateSelector.placeholder;

        /** The source map of states. */
        this.states = StateSelector.states;
    }

    /** @returns {Options.OptionRecord<'Select State' | states[number], '' | states[number]>[]} */
    get options() {
        return [
            this.placeholder,
            ...this.states.map((s) => ({
                label: s,
                value: s,
            })),
        ];
    }
}

/** Province selector module. */
class ProvinceSelector {
    /** Get the country option values. */
    static get provinces() {
        return provinces;
    }

    /** @returns {Options.OptionRecord<'Select Province', ''>} */
    static get placeholder() {
        return {
            label: 'Select Province',
            value: '',
            attrs: { disabled: true },
        };
    }

    /**
     * Instantiate a country selector.
     */
    constructor() {
        /** The placeholder */
        this.placeholder = ProvinceSelector.placeholder;

        /** The source map of provinces. */
        this.provinces = ProvinceSelector.provinces;
    }

    /** @returns {Options.OptionRecord<'Select Province' | provinces[number], '' | provinces[number]>[]} */
    get options() {
        return [
            this.placeholder,
            ...this.provinces.map((value) => ({
                label: value,
                value,
            })),
        ];
    }
}

// <!-- NAMED EXPORTS -->
/**
 * Instantiates internal composable class, then returns its exposed API.
 */
export const useRegionSelectors = () => {
    const country = new CountrySelector();
    const state = new StateSelector();
    const province = new ProvinceSelector();
    return {
        country,
        state,
        province,
    };
};

// <!-- DEFAULT EXPORT -->
export default useRegionSelectors;
