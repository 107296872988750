/**
 * Get a subscription length based on the plan name.
 */
export const useSubscriptionLength = () => {
    /**
     * Default plan duration.
     */
    const DefaultDuration = /** @type {Readonly<Duration>} */ ({
        months: 0,
        years: 1,
    });

    /**
     * Get access to the durations organized by plan name.
     */
    const DurationByPlanName =
        /** @type {Record<string,Readonly<Duration>>} */ ({
            Free: { months: 3 },
            'Level 1': DefaultDuration,
            'Level 2': DefaultDuration,
            'Level 3': DefaultDuration,
            'Level 4': DefaultDuration,
            Enterprise: DefaultDuration,
        });

    /**
     * Get access to the durations organized by plan name.
     */
    const MinDurationByPlanName =
        /** @type {Record<string,Readonly<Duration>>} */ ({
            Free: { months: 3 },
            'Level 1': { years: 1 },
            'Level 2': { years: 1 },
            'Level 3': { years: 1 },
            'Level 4': { years: 1 },
            Enterprise: { years: 1 },
        });

    /**
     * Get access to the durations organized by plan name.
     */
    const MaxDurationByPlanName =
        /** @type {Record<string,Readonly<Duration>>} */ ({
            Free: { months: 3 },
            'Level 1': { years: 5 },
            'Level 2': { years: 5 },
            'Level 3': { years: 5 },
            'Level 4': { years: 5 },
            Enterprise: { years: 5 },
        });

    /**
     * Get the subscription length for the specified plan.
     *
     * @param {Plan.Model['name']} plan
     * @returns {Duration}
     */
    const getSubscriptionLengthByPlanName = (plan) => {
        return plan in DurationByPlanName
            ? DurationByPlanName[plan]
            : DefaultDuration;
    };

    /**
     * Get the min subscription length for the specified plan.
     *
     * @param {Plan.Model['name']} plan
     * @returns {Duration}
     */
    const getMinimumSubscriptionLengthByPlanName = (plan) => {
        return plan in DurationByPlanName
            ? MinDurationByPlanName[plan]
            : { days: 0 };
    };

    /**
     * Get the min subscription length for the specified plan.
     *
     * @param {Plan.Model['name']} plan
     * @returns {Duration}
     */
    const getMaximumSubscriptionLengthByPlanName = (plan) => {
        return plan in DurationByPlanName
            ? MaxDurationByPlanName[plan]
            : { years: 10 };
    };

    // Expose.
    return {
        DefaultDuration,
        DurationByPlanName,
        MinDurationByPlanName,
        MaxDurationByPlanName,
        getSubscriptionLengthByPlanName,
        getMinimumSubscriptionLengthByPlanName,
        getMaximumSubscriptionLengthByPlanName,
    };
};

// <!-- DEFAULT EXPORT -->
export default useSubscriptionLength;
