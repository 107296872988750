/**
 * Internal service class.
 *
 * @template ServiceOptions ServiceOptions passed into this service.
 * @template Constants Constants submodule.
 * @template Events Events submodule.
 * @template State State submodule.
 * @template API Exposed properties.
 */
export class Service {
    /**
     * Instantiate the service class.
     *
     * @param {ServiceOptions} props
     */
    constructor(props) {
        // Bind or inject services required by any submodules.
        this.boot(props);
        // Define the submodule interface.
        this.constants = this.defineConstants();
        this.events = this.defineEvents();
        this.state = this.defineState();
    }

    /**
     * Bind existing dependencies or instantiate missing ones.
     *
     * @param {ServiceOptions} props
     */
    boot(props) {
        // By default, assigns anything present in the dependencies onto this service.
        Object.assign(this, props);
    }

    /**
     * Define submodule responsible for defining static and default properties.
     *
     * @returns {Constants}
     */
    defineConstants() {
        return undefined;
    }

    /**
     * Get the events submodule.
     *
     * @returns {Events}
     */
    defineEvents() {
        return undefined;
    }

    /**
     * Define submodule responsible for defining reactive synchronous and asynchronous state.
     *
     * @returns {State}
     */
    defineState() {
        return undefined;
    }

    /**
     * Exposes the internal API that would be accessible by callers.
     *
     * @returns {API}
     */
    expose() {
        return undefined;
    }
}
