// <!-- API -->
import { computedEager } from '@vueuse/core';

// <!-- UTILITIES -->
import { Option } from '@/utils/options';
import { Service } from '@/utils/Service';
import { DurationISO } from '@/utils/datetime/DurationISO';

// <!-- SUBMODULES -->

class Constants {
    /**
     * Instantiate a Constants instance.
     */
    constructor() {
        /** Configure the default options. */
        this.DefaultDurations = /** @type {Duration[]} */ ([
            {
                months: 3,
            },
            {
                years: 1,
            },
            {
                years: 2,
            },
            {
                years: 3,
            },
            {
                years: 4,
            },
            {
                years: 5,
            },
        ]);
    }
}

class State {
    /**
     * Instantiate a State instance.
     *
     * @param {Omit<DurationSelector, 'state' | 'boot' | 'defineInterface' | 'defineConstants' | 'defineEvents' | 'defineState' | 'registerEventListeners'>} context
     */
    constructor(context) {
        // Add non-enumerable context property.
        this.context = context;
        Object.defineProperty(this, 'context', {
            enumerable: false,
            writable: false,
        });

        // Define the state.
        this.defineComputed();
    }

    defineComputed() {
        const { DefaultDurations } = this.context.constants;

        // Define the computed property of options.
        this.options = computedEager(() => {
            const durations = DefaultDurations;
            const records = durations.map((duration) => {
                return {
                    value: DurationISO.format(duration),
                    label: DurationISO.label(duration),
                };
            });
            return [Option.placeholder('Select Length', ''), ...records];
        });
    }
}

// <!-- PRIMARY MODULE -->

/**
 * The internal composable context.
 *
 * @extends {Service<void, Constants, void, State, DurationSelector>}
 */
class DurationSelector extends Service {
    /**
     * Instantiates composable service class.
     *
     * @param {void} props
     */
    constructor(props) {
        super(props);
    }

    /** Instantiate the submodule. */
    defineConstants() {
        return new Constants();
    }

    /** Instantiate the submodule. */
    defineState() {
        return new State(this);
    }

    /**
     * Parse duration from option value.
     *
     * @param {string} value
     */
    getDurationByValue(value) {
        const duration = DurationISO.parse(value);
        return duration;
    }

    /**
     * Format duration into option value.
     *
     * @param {Duration} duration
     */
    getValueByDuration(duration) {
        const value = DurationISO.format(duration);
        return value;
    }

    /**
     * Format duration into label value.
     *
     * @param {Duration} duration
     */
    getLabelByDuration(duration) {
        const label = DurationISO.label(duration);
        return label;
    }

    /** Expose this instance, by default. */
    expose() {
        return this;
    }
}

// <!-- NAMED EXPORTS -->
/**
 * Instantiates internal composable class, then returns its exposed API.
 */
export const useDurationSelector = () => {
    const instance = new DurationSelector(null);
    // instance.state.variable
    return instance.expose();
};

// <!-- DEFAULT EXPORT -->
export default useDurationSelector;
